<template>
  <div id="app">
    <div class="page" id="page">
      <vNavigation class="page__header"/>
      <router-view/>
      <vFooter/>
    </div>
    <div class="flashpool flash__wrapper">
      <flash-message class="flash"></flash-message>
    </div>
  </div>
</template>

<script>
  import DocumentCollectionMixin from './mixins/documentCollections/DocumentCollectionsMixin'
  import vFooter                  from './components/vFooter'
  export default {
    name: 'App',
    components: {
      vFooter,
    },
    mixins: [
      DocumentCollectionMixin,
    ],
    watch: {
      '$store.state.warning': function (message) {
        if (message) this.flashWarning(message, {timeout: 5000})
      },
      '$store.state.error': function (err) {
        if (err) this.flashError(err.message, {timeout: 9000})
      }
    },
    mounted() {
      this.loadLatestDocumentVersions('pageTexts')
    }
  }
</script>
<style lang="scss">
  .flashpool {
    position: fixed;
    top: 20px;
    right: 20px;
    max-height: 400px;
    width: 260px;
    -webkit-perspective: 400px;
    perspective: 400px;
    z-index: 4;
  }
  .flashpool .flash__message {
    width: 260px;
    transition: all .5s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: Oxygen, sans-serif;
    font-size: 13px;
    line-height: 130%;
  }
  .flash__message {
    box-sizing: border-box;
    padding: 15px 30px 15px 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #ccc;
    position: relative;
  }
  body {
    background-color: #4d5760;
  }
  .shadow {
    box-shadow: 0 0 1em 0 rgba(40,40,40,0.5);
  }
  .card {
    margin: 0.5em;
    background-color: rgba(255, 255, 255, 0.98);
    color: #262626;
    box-shadow: .12em .12em .29em 0 rgba(40,40,50,0.3);
  }
  .page__content__block,
  .page__content {
    background-color: white!important;
  }
  .block {
    margin-left: 1rem;
  }
  .field input {
    width: 100%;
  }
  .flex-r {
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .flex-c {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .center {
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: center;
  }

  .column {
    display: grid;
    grid-row-gap: 0.5rem;
  }
  .fw,
  .full-width {
    width: 100%;
  }
  .fh,
  .full-height {
    height: 100%;
  }
  .cc,
  .content-center {
    text-align: center;
  }
  .cr,
  .content-right {
    text-align: right;
  }
  .cl,
  .content-left {
    text-align: left;
  }

  .p0 { padding: 0; }
  .pt0 { padding-top: 0; }
  .pr0 { padding-right: 0; }
  .pb0 { padding-bottom: 0; }
  .pl0 { padding-left: 0; }
  .px0 { padding-left: 0; padding-right: 0; }
  .py0 { padding-top: 0; padding-bottom: 0; }
  .p1 { padding: .5rem; }
  .pt1 { padding-top: .5rem; }
  .pr1 { padding-right: .5rem; }
  .pb1 { padding-bottom: .5rem; }
  .pl1 { padding-left: .5rem; }
  .px1 { padding-left: .5rem; padding-right: .5rem; }
  .py1 { padding-top: .5rem; padding-bottom: .5rem; }
  .p2 { padding: 1rem; }
  .pt2 { padding-top: 1rem; }
  .pr2 { padding-right: 1rem; }
  .pb2 { padding-bottom: 1rem; }
  .pl2 { padding-left: 1rem; }
  .px2 { padding-left: 1rem; padding-right: 1rem; }
  .py2 { padding-top: 1rem; padding-bottom: 1rem; }
  .p3 { padding: 1.5rem; }
  .pt3 { padding-top: 1.5rem; }
  .pr3 { padding-right: 1.5rem; }
  .pb3 { padding-bottom: 1.5rem; }
  .pl3 { padding-left: 1.5rem; }
  .px3 { padding-left: 1.5rem; padding-right: 1.5rem; }
  .py3 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
  .p4 { padding: 2rem; }
  .pt4 { padding-top: 2rem; }
  .pr4 { padding-right: 2rem; }
  .pb4 { padding-bottom: 2rem; }
  .pl4 { padding-left: 2rem; }
  .px4 { padding-left: 2rem; padding-right: 2rem; }
  .py4 { padding-top: 2rem; padding-bottom: 2rem; }
  .p5 { padding: 2.5rem; }
  .pt5 { padding-top: 2.5rem; }
  .pr5 { padding-right: 2.5rem; }
  .pb5 { padding-bottom: 2.5rem; }
  .pl5 { padding-left: 2.5rem; }
  .px5 { padding-left: 2.5rem; padding-right: 2.5rem; }
  .py5 { padding-top: 2.5rem; padding-bottom: 2.5rem; }
  .p6 { padding: 3rem; }
  .pt6 { padding-top: 3rem; }
  .pr6 { padding-right: 3rem; }
  .pb6 { padding-bottom: 3rem; }
  .pl6 { padding-left: 3rem; }
  .px6 { padding-left: 3rem; padding-right: 3rem; }
  .py6 { padding-top: 3rem; padding-bottom: 3rem; }
  .p7 { padding: 3.5rem; }
  .pt7 { padding-top: 3.5rem; }
  .pr7 { padding-right: 3.5rem; }
  .pb7 { padding-bottom: 3.5rem; }
  .pl7 { padding-left: 3.5rem; }
  .px7 { padding-left: 3.5rem; padding-right: 3.5rem; }
  .py7 { padding-top: 3.5rem; padding-bottom: 3.5rem; }
  .p8 { padding: 4rem; }
  .pt8 { padding-top: 4rem; }
  .pr8 { padding-right: 4rem; }
  .pb8 { padding-bottom: 4rem; }
  .pl8 { padding-left: 4rem; }
  .px8 { padding-left: 4rem; padding-right: 4rem; }
  .py8 { padding-top: 4rem; padding-bottom: 4rem; }
  .p9 { padding: 4.5rem; }
  .pt9 { padding-top: 4.5rem; }
  .pr9 { padding-right: 4.5rem; }
  .pb9 { padding-bottom: 4.5rem; }
  .pl9 { padding-left: 4.5rem; }
  .px9 { padding-left: 4.5rem; padding-right: 4.5rem; }
  .py9 { padding-top: 4.5rem; padding-bottom: 4.5rem; }
  .p10 { padding: 5rem; }
  .pt10 { padding-top: 5rem; }
  .pr10 { padding-right: 5rem; }
  .pb10 { padding-bottom: 5rem; }
  .pl10 { padding-left: 5rem; }
  .px10 { padding-left: 5rem; padding-right: 5rem; }
  .py10 { padding-top: 5rem; padding-bottom: 5rem; }

  .m0 { margin: 0; }
  .mt0 { margin-top: 0; }
  .mr0 { margin-right: 0; }
  .mb0 { margin-bottom: 0; }
  .ml0 { margin-left: 0; }
  .mx0 { margin-left: 0; margin-right: 0; }
  .my0 { margin-top: 0; margin-bottom: 0; }
  .m1 { margin: .5rem; }
  .mt1 { margin-top: .5rem; }
  .mr1 { margin-right: .5rem; }
  .mb1 { margin-bottom: .5rem; }
  .ml1 { margin-left: .5rem; }
  .mx1 { margin-left: .5rem; margin-right: .5rem; }
  .my1 { margin-top: .5rem; margin-bottom: .5rem; }
  .m2 { margin: 1rem; }
  .mt2 { margin-top: 1rem; }
  .mr2 { margin-right: 1rem; }
  .mb2 { margin-bottom: 1rem; }
  .ml2 { margin-left: 1rem; }
  .mx2 { margin-left: 1rem; margin-right: 1rem; }
  .my2 { margin-top: 1rem; margin-bottom: 1rem; }
  .m3 { margin: 1.5rem; }
  .mt3 { margin-top: 1.5rem; }
  .mr3 { margin-right: 1.5rem; }
  .mb3 { margin-bottom: 1.5rem; }
  .ml3 { margin-left: 1.5rem; }
  .mx3 { margin-left: 1.5rem; margin-right: 1.5rem; }
  .my3 { margin-top: 1.5rem; margin-bottom: 1.5rem; }
  .m4 { margin: 2rem; }
  .mt4 { margin-top: 2rem; }
  .mr4 { margin-right: 2rem; }
  .mb4 { margin-bottom: 2rem; }
  .ml4 { margin-left: 2rem; }
  .mx4 { margin-left: 2rem; margin-right: 2rem; }
  .my4 { margin-top: 2rem; margin-bottom: 2rem; }
  .m5 { margin: 2.5rem; }
  .mt5 { margin-top: 2.5rem; }
  .mr5 { margin-right: 2.5rem; }
  .mb5 { margin-bottom: 2.5rem; }
  .ml5 { margin-left: 2.5rem; }
  .mx5 { margin-left: 2.5rem; margin-right: 2.5rem; }
  .my5 { margin-top: 2.5rem; margin-bottom: 2.5rem; }
  .m6 { margin: 3rem; }
  .mt6 { margin-top: 3rem; }
  .mr6 { margin-right: 3rem; }
  .mb6 { margin-bottom: 3rem; }
  .ml6 { margin-left: 3rem; }
  .mx6 { margin-left: 3rem; margin-right: 3rem; }
  .my6 { margin-top: 3rem; margin-bottom: 3rem; }
  .m7 { margin: 3.5rem; }
  .mt7 { margin-top: 3.5rem; }
  .mr7 { margin-right: 3.5rem; }
  .mb7 { margin-bottom: 3.5rem; }
  .ml7 { margin-left: 3.5rem; }
  .mx7 { margin-left: 3.5rem; margin-right: 3.5rem; }
  .my7 { margin-top: 3.5rem; margin-bottom: 3.5rem; }
  .m8 { margin: 4rem; }
  .mt8 { margin-top: 4rem; }
  .mr8 { margin-right: 4rem; }
  .mb8 { margin-bottom: 4rem; }
  .ml8 { margin-left: 4rem; }
  .mx8 { margin-left: 4rem; margin-right: 4rem; }
  .my8 { margin-top: 4rem; margin-bottom: 4rem; }
  .m9 { margin: 4.5rem; }
  .mt9 { margin-top: 4.5rem; }
  .mr9 { margin-right: 4.5rem; }
  .mb9 { margin-bottom: 4.5rem; }
  .ml9 { margin-left: 4.5rem; }
  .mx9 { margin-left: 4.5rem; margin-right: 4.5rem; }
  .my9 { margin-top: 4.5rem; margin-bottom: 4.5rem; }
  .m10 { margin: 5rem; }
  .mt10 { margin-top: 5rem; }
  .mr10 { margin-right: 5rem; }
  .mb10 { margin-bottom: 5rem; }
  .ml10 { margin-left: 5rem; }
  .mx10 { margin-left: 5rem; margin-right: 5rem; }
  .my10 { margin-top: 5rem; margin-bottom: 5rem; }

  .icon::before { display: none!important; }
  .center svg.material-design-icon__svg,
  .icon svg.material-design-icon__svg,
  .icon2x svg.material-design-icon__svg,
  .icon3x svg.material-design-icon__svg,
  .icon4x svg.material-design-icon__svg,
  .icon5x svg.material-design-icon__svg,
  .icon6x svg.material-design-icon__svg,
  .icon7x svg.material-design-icon__svg,
  .icon8x svg.material-design-icon__svg,
  .icon9x svg.material-design-icon__svg,
  .icon10x svg.material-design-icon__svg,
  {
    bottom: 0!important;
  }
  .icon2x {font-size: 2rem; color: #003d73;}
  .icon3x {font-size: 3rem; color: #003d73;}
  .icon4x {font-size: 4rem; color: #003d73;}
  .icon5x {font-size: 5rem; color: #003d73;}
  .icon6x {font-size: 6rem; color: #003d73;}
  .icon7x {font-size: 7rem; color: #003d73;}
  .icon8x {font-size: 8rem; color: #003d73;}
  .icon9x {font-size: 9rem; color: #003d73;}
  .icon10x {font-size: 10rem; color: #003d73;}

  .error { color: darkred; }
  .danger { color: darkred; }
  .warning { color: orange; }

  .message.error {
    background-color: #ffc5c5;
  }
  .message.danger {
    background-color: #ffc5c5;
  }
  .message.warning {
    padding: .5rem;
    background-color: #FFEAC1;
  }

  .bgerror {
    background-color: darkred;
    color: white
  }
  .bgdanger {
    background-color: darkred;
    color: white
  }
  .bgwarning { background-color: orange; }


  .disabled { color: darkgrey; }

  .hu-color-picker .color-show,
  .hu-color-picker .colors,
  .hu-color-picker .color-type {
    display: none!important;
  }

  .colors.history {
    display: block!important;
  }
  .news {
    .image .frame,
    .frame img {
      width: 100%!important;
    }
  }

  .button[disabled],
  button[disabled]
  {
    background-color: slategrey;
    color: whitesmoke;
    box-shadow: none!important;
  }
  .button[disabled]:hover,
  button[disabled]:hover {
    background-color: slategrey!important;
    color: whitesmoke!important;
    box-shadow: none!important;
  }
  .chars {
    font-family: monospace;
  }
  h1, h2, h3, h4, p, ul, ol, blockquote {
    max-width: min(100%, 80rem)!important;
  }
  h2 {
    margin: 1.4162388333rem 0!important; /* AU style override */
  }
  a {
    text-decoration: none!important;
    cursor: pointer;
  }
  img {
    max-height: none; /* AU style override */
  }

  .md>* {
    max-width: 100%!important;
  }
  .newsTeaser .md {
    width: 30rem;
  }

</style>
