'use strict';
// EcmaScript 5.1 due to vue-cli configuration file import compatibility

const nconf = require('nconf')
const credentials = require("./secrets/credentials")

nconf.env(['PORT', 'NODE_ENV'])
  .argv({
    'e': {
      alias: 'NODE_ENV',
      describe: 'Set production or development mode.',
      demand: false,
      default: 'development'
    },
    'n': {
      alias: "neo4j",
      describe: "Use local or remote neo4j instance",
      demand: false,
      default: "local"
    },
    'd': {
      alias: "debug",
      describe: "debug-level logging",
      demand: false,
      default: true
    }
  })
  .defaults({
    'USERNAME': credentials.neo4j.login,
    'PASSWORD' : credentials.neo4j.password,
    'databaseName': 'neo4j', // community edition limit
    'neo4j': 'local',
    'neo4j-local': 'bolt://localhost:7687',
    processPort: 81,                                        // port used internally by (backend) production process
    testingProcessPort: 8001,                                 // port used internally by (backend) testing process
                                                            // frontEndDevelopmentPort: set in ./client/package.json
    testingUrl: 'http://hope-project.dk:443/test-backend',  // url as addressed by test client (compare package.json>scripts.start port argument
    developmentUrl: 'http://localhost:8001',                // url as addressed by development client (compare package.json>scripts.start port argument
    productionUrl: 'https://hope-project.dk',               // url as addressed by production client
    productionOriginPattern: '^https:(?:www.)?//hope-project(?:.au)?.dk$',
    siteUrl: '',                                            // e.g. (github pages) repository name
    apiPath: '/api/v1',
    rootUserEmail: 'max@cas.au.dk',
    editorEmails: [
      'max@cas.au.dk',
      'mfl@ps.au.dk',
      'fridahaestrup@live.dk',
      'frihae@cas.au.dk',
      'michael@ps.au.dk',
      'andreas.roepstorff@cas.au.dk',
      'ran@ifs.ku.dk',
      'sljo@dtu.dk',
      'csun@cas.au.dk',
      'rbkh@cc.au.dk',
      'Charlotte.norholm@cas.au.dk',
      'julieziska@cas.au.dk',
    ],
    debugNeo4jConnection: false,
    debugRouting: {
      request: false,
      url: true,
    },
    debugSecurity: false,
    debugActionQuery: true,
    //debugMapQueryObjects: false,
    debugTranspileQueryResults: {
      mapRecords: false,
      mapNeo4jSymbol: false,
      mapObjectLiteral: false,
    },
    debugUnescapeCypherData: {
      array: false,
      object: false,
      attributes: false
    }
  });

module.exports = nconf
