export const isUnique = (arrayItem, index, self) => self.indexOf(arrayItem) === index // a.k.a. isFirstOfItsValueInArray (indexOf will only match first)

export function capitalize(string) {
  let capitalized = ''
  string = string ?? ''
  string = string.toString()
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return capitalized
}
export function decapitalize(string) {
  let decapitalized = ''
  string = string ?? ''
  string = string.toString()
  if (string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
  }
  return decapitalized
}
